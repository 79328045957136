import React from 'react';
import { Container, Row, Cell, Section } from '@components/grid';
import Helper from '@webpack/helper.js';

import translator from '@webpack/translator';
const t = translator('front_office.views.recurrent_invoices.index_view.');

const IndexView = ({ createUrl, currency, recurrentInvoices }) => {
  const textForCreate = <span dangerouslySetInnerHTML={{ __html: t('text_for_create', { createUrl: createUrl }) }}></span>
  const isRecurrentInvoicesPresent = recurrentInvoices.length
  return (
    <div className="invoices">
      <Container>
        <Row>
          <Cell>
            <Section className="widget-v2">
              <Row>
                <Cell md={12} lg={12}>
                  <h3 className="header-v2 flex text-align-start without_margin">
                    {t('recurrent_invoices')}
                  </h3>
                  {isRecurrentInvoicesPresent ? textForCreate : ''}
                </Cell>
              </Row>
            </Section>
          </Cell>
        </Row>
      </Container>
      {isRecurrentInvoicesPresent ?
        (<Index recurrentInvoices={recurrentInvoices} currency={currency} />)
        : textForCreate
      }
    </div>
  );
};

const Index = ({ recurrentInvoices, currency }) => {
  const recurrentInvoicesTrs = recurrentInvoices.map((recInv) => (
    <tr key={recInv.id}>
      <td>{recInv.id}</td>
      <td>{recInv.day_of_month}</td>
      <td>{recInv.sending_frequency}</td>
      <td>
        <span className="fw-semi-bold">{Helper.formatMoney(recInv.amount_of_money, currency)}</span>
      </td>
      <td>{recInv.legal_person}</td>
      <td>{recInv.email}</td>
      <td>{recInv.kpp}</td>
      <td>
        <a href={recInv.destroy_path} data-method="delete" data-confirm={t('delete_confirm')}>
          {t('delete_button')}
        </a>
      </td>
      <td>
        <a href={recInv.edit_path} data-method="get">
          {t('edit_button')}
        </a>
      </td>
    </tr>
  ));

  return (
    <Row>
      <Cell md={12} lg={12}>
        <Section className="widget-v2 same_height">
          <table id="datatable-table" className="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>{t('day_of_month')}</th>
                <th>{t('sending_frequency')}</th>
                <th>{t('amount_of_money')}</th>
                <th>{t('legal_person')}</th>
                <th>{t('email')}</th>
                <th>{t('kpp')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{recurrentInvoicesTrs}</tbody>
          </table>
        </Section>
      </Cell>
    </Row>
  );
};

export default IndexView;
