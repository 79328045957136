import React, {useState} from 'react';
import axios from 'axios';
import {Switch, Button, Grid} from 'antd';
import {useNavigate} from 'react-router-dom';
import {useNotificationsState} from './State';
import translator from '@webpack/translator';
const t = translator('front_office.views.altegio_notification_settings.');

const TableActions = (
  {
    openUpsaleModal,
    limitModal,
    id,
    is_enabled,
    action_type,
    modal_data,
    items
  }
) => {
  const {state} = useNotificationsState();
  const [isEnabled, setIsEnabled] = useState(is_enabled);
  const [isToggling, setIsToggling] = useState(false);
  const navigate = useNavigate();
  const isCreate = !id;
  const linkText = isCreate ? t('index.create') : t('index.show');
  const screens = Grid.useBreakpoint();

  const onLinkClick = isCreate
    ?
    e => {
      e.stopPropagation();
      if (modal_data) {
        openUpsaleModal(action_type, modal_data);
      } else {
        navigate(`${state.selectedSalon}/${action_type}/new`);
      }
    }
    :
    _e => {
      navigate(`${action_type}/${id}`);
    };

  const toggle = (is_enabled, _e) => {
    setIsEnabled(is_enabled);
    setIsToggling(true);

    return axios({
      method: 'PATCH',
      data: {altegio_notification_setting: {is_enabled}},
      url: `/front_office/altegio_notification_settings/${action_type}/${id}`,
      headers: {
        Accept: 'application/javascript',
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      },
    }).then(({data}) => {
      if (!data.success) {
        setIsEnabled(!is_enabled);
      }
    }).catch((_error) => {
      setIsEnabled(!is_enabled);
    }).finally(() => {
      setIsToggling(false);
    });
  };

  return isCreate
    ?
    <div className='altegio-notification-settings-table__actions'>
      <Button className='ant-custom-btn' type={screens.md ? 'link' : 'primary'} onClick={onLinkClick}>{linkText}</Button>
    </div>
    :
    <div className='altegio-notification-settings-table__actions'>
      <Button className='ant-custom-btn' type={screens.md ? 'link' : 'primary'} onClick={onLinkClick}>{linkText}</Button>
      <Switch
        loading={isToggling}
        checked={isEnabled}
        onChange={toggle}
      />
    </div>
};

export default TableActions;
