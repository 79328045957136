import React from 'react';
import { Row, Cell } from '@components/grid';
import MedalIcon from '../../../Icons/MedalIcon';

import translator from '@webpack/translator';
const t = translator('front_office.views.integrations.telegram_tdlib.last_step.');

export default () => {
  window.play_firework();
  return (
    <div className="telegram-flow_split-pane">
      <div className="telegram-flow_last-step">
        <Row>
          <Cell>
            <MedalIcon />
          </Cell>
        </Row>
        <Row>
          <Cell className="telegram-flow_title">
            <h1>
              {t('title_1')}
              <br />
              {t('title_2')}
            </h1>
          </Cell>
        </Row>
        <Row>
          <Cell className="telegram-flow_sub-title telegram-flow_link">
            <div dangerouslySetInnerHTML={{ __html: t('sub_title_start') }} />
            <br />
            <br />
            <div dangerouslySetInnerHTML={{ __html: t('sub_title_end') }} />
          </Cell>
        </Row>
      </div>
    </div>
  );
};
